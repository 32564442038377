$primary-font: SF Pro Text, serif;
.text-center {
  text-align: center;
}

.container-order
  .ant-form-item-has-error
  .ant-input:not(.ant-form-item-has-error .ant-input-disabled),
.container-order
  .ant-form-item-has-error
  .ant-input-affix-wrapper:not(.ant-form-item-has-error
    .ant-input-affix-wrapper-disabled) {
  border-color: #ff4d4f !important;
}

.container-qrcode {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.pd-10-percent {
  padding: 10%;
}

#qr-print {
  margin-left: 6px;
  font-weight: 900;
  font-size: 20px;
  img {
    display: inline-block !important;
    margin-left: -12px;
  }
}
.fw-bold {
  font-weight: 600;
}

.btn-center {
  display: flex;
  justify-content: center;
}

.mg-10 {
  margin: 10px;
}
.pd20 {
  padding: 20px;
}

.demo-option-label-item > span {
  margin-right: 6px;
}

.title-choose-input-location {
  color: #cecfd0;
  font-size: 14px;
  line-height: 20px;
}

.radioGroup {
  width: 100%;
  padding-left: 60px;
  @media (max-width: 900px) {
    padding-left: 15px;
  }
}

.radio-group-mobile {
  padding-left: 0px !important;
  margin-top: 20px !important;
}

.radioGroup > label {
  color: #cecfd0;
  //margin-right: 160px;
}

// .body-input-location {
//   margin-left: 190px !important;
//   margin-top: 30px !important;
// }

.body-input-location-mobile {
  margin-top: 30px !important;
}

.radioGroup {
  .ant-radio-inner::after {
    background-color: #87bf3e !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #87bf3e !important;
  }
  .ant-radio-inner {
    width: 20px !important;
    height: 20px !important;
    background-color: #24262b !important;
    border-color: #87bf3e !important;
  }
  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(1);
  }
  .ant-radio-wrapper {
    color: #cecfd0 !important;
  }
}

.pac-target-input {
  width: 100%;
  background: #2c3034;
  color: #f0f0f0;
  border: none;
  border-radius: 8px;
  height: 48px;
  padding-left: 10px;
}
.dropdown-select-step-popup-first .pac-target-input::placeholder{
  color: #3d3d3d;
}

.pac-container {
  z-index: 107200000;
  background: #2c3034;
  .pac-item {
    border: none !important;
    color: #cecfd0;
    &:hover {
      background: #333d47;
    }
  }
}

.pac-matched {
  color: #cecfd0;
}

.pac-item-query {
  color: #cecfd0;
}

.address-hidden {
  z-index: -99999;
  margin-top: -15px;
  .ant-form-item-control-input {
    display: none;
  }
}

.checkBoxGps {
  .ant-checkbox-inner {
    background-color: #24262b !important;
    border: 1px solid #9187f2;
  }
  .ant-checkbox + span {
    color: #777777;
    font-size: 14px;
    font-family: $primary-font;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #9187f2 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #9187f2 !important;
  }
}
.icon-info {
  position: relative;
  left: -10px;
  cursor: pointer;
}
.require-star {
  color: #d7aa08 !important;
  font-size: 12px;
  position: relative;
  top: -0.5px;
}
.radioGroup svg {
  width: 12px;
  height: 12px;
  color: #9187f2;
  margin-bottom: 1px;
}

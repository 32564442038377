//@import "styles/index";
//@import "~antd/dist/antd.css";
$color-green-txt: #64af02;

#admin-id {
  overflow-y: scroll;
}

#admin-id::-webkit-scrollbar-thumb {
  background: #454a58 !important;
  border-radius: 10px;
}

#admin-id::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  @media (max-width: 1200px) {
    width: 4px;
    height: 4px;
  }
}

/* Track */
#admin-id::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #36354b;
  border-radius: 10px;
}

/* Handle on hover */
#admin-id::-webkit-scrollbar-thumb:hover {
  background: #454a58;
  //background: #FFFFFF;
}

.info-contents-step1 .ant-select-selector,
.select-dropdown-step1 {
  background-color: #F2F2F2 !important;
}
.select-dropdown-step1
  .rc-virtual-list
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ffffff;
}
.select-dropdown-step1 .ant-menu-inline,
.ant-select-item,
.ant-menu-item a,
.ant-menu-submenu,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  color: #ffffff;
}

.select-dropdown-step1
  .ant-select-item
  ant-select-item-option
  ant-select-item-option-selected {
  background-color: #2c3034;
}

.select-dropdown-step1
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #000000 ;
  background-color: #ffffff;
}

//.select-dropdown-step1 .ant-select-item-option-content {
//  font-size: 14px !important;
//}

.select-dropdown-step1 .ant-select-item {
  font-size: 14px;
}

.select-dropdown-step1 .ant-select-item-option-grouped {
  font-size: 12px !important;
}

.info-contents-step1 {
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;

  font-size: 14px !important;
  color: #2D3034 ;
  background-color: #F2F2F2;
  .ant-select-selection-placeholder {
    color: #2D3034 !important;
  }
}

.check-box-col {
  padding-bottom: 25px;
}

.check-box-col:last-child {
  padding-bottom: 0;
}

.table-steps .ant-table-thead > tr > th {
  border-top: 1px solid rgba(131, 131, 131, 0.4) !important;
  border-left: 1px solid rgba(131, 131, 131, 0.4) !important;
  border-right: 1px solid rgba(131, 131, 131, 0.4) !important;
  border-bottom: 1px solid rgba(131, 131, 131, 0.2) !important;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  line-height: 35px;
}
.table-steps-insight .ant-table-thead > tr > th {
  border-top: 1px solid rgba(131, 131, 131, 0.4) !important;
  border-left: 1px solid rgba(131, 131, 131, 0.4) !important;
  border-right: 1px solid rgba(131, 131, 131, 0.4) !important;
  border-bottom: 1px solid rgba(131, 131, 131, 0.2) !important;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  line-height: 35px;
}
.table-steps .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(131, 131, 131, 0.2);
  border-left: 1px solid rgba(131, 131, 131, 0.4) !important;
  border-right: 1px solid rgba(131, 131, 131, 0.4);
  line-height: 35px !important;
  white-space: normal;
  background: #FFFFFF;
}
.table-steps-insight .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(131, 131, 131, 0.2);
  border-left: 1px solid rgba(131, 131, 131, 0.4) !important;
  border-right: 1px solid rgba(131, 131, 131, 0.4);
  line-height: 35px !important;
}

.table-steps .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid rgba(131, 131, 131, 0.4);
  border-left: 1px solid rgba(131, 131, 131, 0.4);
  border-right: 1px solid rgba(131, 131, 131, 0.4);
}
.table-steps-insight .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid rgba(131, 131, 131, 0.4);
  border-left: 1px solid rgba(131, 131, 131, 0.4);
  border-right: 1px solid rgba(131, 131, 131, 0.4);
}
// .table-steps-onboarding .ant-table-tbody > tr.ant-table-row:hover > td,.table-steps-onboarding .ant-table-tbody > tr > td.ant-table-cell-row-hover{
//   background: #faf8f8 !important
// }
// .table-steps-onboarding .ant-table-tbody .alert-input{
//   background:#e0e0e0 !important ;
//   .ant-select-selector{
//     background:#e0e0e0 !important;
//   }
// }
.dropdown-select-step-popup-first {
  background-color: #ffffff;
  .pac-target-input{
    background: #ffffff !important;
    color: #000000 !important;
  }
}
.condition-mobile {
  color: #5b9f02;
}

.account-step1 {
  span {
    color: #82b73f;
  }
}

.note-step1 {
  font-style: italic !important;
  color: #ffa800;
}

.form-item-step6 .input-step6 {
  background-color: #FFFFFF;
  border-radius: 4px;
  border: none;
  height: 40px;
  input {
    background-color: #FFFFFF;
    color: #000000 !important;
  }
  .ant-input::placeholder {
    color: #777777 !important;
  }
}

.color-text-green {
  color: $color-green-txt;
}
.insight-package-table-onboarding .ant-table-tbody{
  background: #F2F2F2 !important;
  .alert-input{
    background: #F2F2F2 !important;
    .ant-select-selector{
      background: #F2F2F2 !important;
    }
  }
}
.insight-package-table-onboarding .ant-table-tbody > tr.ant-table-row:hover > td,.insight-package-table-onboarding .ant-table-tbody > tr > td.ant-table-cell-row-hover{
  //background: #E3E7F2 !important;
  background: #FFFFFF !important;
}
.pac-container {
  .pac-item-selected{
    background: #F1F1F1;
  }
}
.popup-content-first .anticon-arrow-left{
  filter: brightness(0.6);
}
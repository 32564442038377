@import "styles/index";

.main-header {
  background: #2c3034;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 1200px) {
    height: 90px;
  }
}

.main-header-mobile {
  background: #2c3034;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 900px) {
    max-height: 120px;
  }
}

.fix-header {
  position: sticky;
  top: 0;
  z-index: 1001;
  width: 100%;
  //height: 100px;
}

.ant-select-arrow {
  color: #f0f0f0;
}

// .logo {
// }

.icon-menu-fold {
  cursor: pointer !important;
  font-size: 16px !important;
  color: #0071a9 !important;
  padding: 15px 20px !important;
}

.right-content {
  display: flex;
  align-items: center;
  height: 100%;

  .notification,
  .dropdown-info {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 15px;
    border-left: 1px solid #eaedf1;

    &:hover {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }

  .point {
    z-index: 10;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #0071a9;
    right: 0;
  }
}

.notification {
  margin-right: 20px;
  font-size: 14px;
}

.icon-notification {
  font-size: 25px;
}

.avatar {
  position: relative;
  width: 21px;
  margin-right: 5px;
}

.count-notification {
  position: absolute;
  right: 18px;
  top: 8px;
  min-width: 18px;
  height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  white-space: nowrap;
  color: $white;
  background: $red;
}

.qr-text {
  font-size: 26px;
  color: $red;
  font-weight: bold;
}

.code-text {
  font-size: 26px;
  color: $blue;
  font-weight: bold;
}

.menu-notification {
  padding: 10px;
  min-width: 300px;
  cursor: pointer;

  .logo-noti {
    padding: 10px 5px;
    margin-right: 10px;
    background: #ffffff;
    border: 1px solid #eaedf1;
    border-radius: 6px;
  }
}

.menu-notification:hover {
  background: #e8f1f6;
}

.view-all {
  text-align: center;
  color: #005c8a;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 0;
}
.menu-notify .ant-dropdown-menu-item:hover {
  background: #212429;
}

// .ant-dropdown {
//   //min-width: 220px !important;
// }

.option {
  color: Grey;
  background: #2c3034;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    color: #ffffff ;
    background: #333d47 ;
    //.none {
    //  display: inline !important;
    //}
  }
}

.option-mobile {
  color: Grey !important;
  background: none !important;
  font-size: 14px;
  font-weight: 500;

  height: 57px !important;
  &:hover {
    color: #ffffff !important;
    background: #333d47 !important;
  }
}

.option:not(:last-child) {
  border-bottom: 0.5px solid #333d47;
}

.avatar-name {
  width: 48px;
  margin-right: 5px;
  cursor: pointer;
}

.select {
  background: #2c3034 !important;
  box-shadow: none !important;
  border-radius: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;
  &:hover {
    background: #333d47;
  }
}

.carousel-button-back-pc {
  background: none;
  border-radius: 15px;
  border: 1px solid #9187f2;
}

.carousel-button-next-pc {
  background: #9187f2;
  margin-left: 10px;
  border-radius: 15px;
  border: none;
}

.image-slide {
  background-image: url(../../assets/img/icon_new/slide1-1.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

.number-notify {
  position: absolute;
  left: 20px;
  top: -16px;
  padding: 1px 4px 1px 4px;
  border-radius: 100%;
  background-color: green;
  color: white;
  border: 2px solid white;
}
.number-notify-small {
  position: absolute;
  left: 20px;
  top: -16px;
  padding: 0px 6px 0px 6px;
  border-radius: 100%;
  background-color: green;
  color: white;
  border: 2px solid white;
}
.number-notifys {
  position: absolute;
  // right: 0px;
  left: 20px;
  top: -21px;
  padding: 3px 2px 3px 2px;
  border-radius: 100%;
  background-color: green;
  color: white;
  border: 2px solid white;
}
.number-notify-mobile {
  position: relative;
  top: -32px;
  right: -8px;
  padding: 0px 4px 0px 4px;
  border-radius: 50%;
  background-color: green;
  color: white;
  border: 2px solid white;
}
.number-notifys-mobile {
  position: relative;
  top: -35px;
  right: -8px;
  padding: 3px 2px 3px 2px;
  border-radius: 50%;
  background-color: green;
  color: white;
  border: 2px solid white;
}
.number-notify-avg-mobile {
  position: relative;
  top: -32px;
  right: -8px;
  padding: 1px 2px 1px 2px;
  border-radius: 50%;
  background-color: green;
  color: white;
  border: 2px solid white;
}
// .ant-dropdown{
//   width: 100%;
// }
.ant-affix {
  top: 0 !important;
  z-index: 1000 !important;
}

.container-detail-header-home {
  height: 100%;
  display: flex;
  align-items: center;
  .container-button-login-header-home {
    display: flex;
    justify-content: center;
    .button-login-home {
      // background: linear-gradient(270deg, #FDB715 0%, #FFD571 100%);
      // border-radius: 4px;
      background: linear-gradient(270deg, #dfa520 0%, #f7bd35 100%);
      border-radius: 4px;
      border: none;
      height: 32px;
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height, or 122% */

      text-align: center;

      color: #513c06;

      padding: 0px 14px;
      cursor: pointer;
      @media (max-width: 900px) {
        height: 32px;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 138% */

        text-align: center;

        color: #5a440b;
      }
      @media (max-width: 320px) {
        padding: 0px 3px !important;
      }
    }
  }
  .container-button-register-header-home {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    .button-register-home {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height, or 122% */

      text-align: center;

      color: #4a4396;
      @media (max-width: 900px) {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 138% */

        text-align: center;

        color: #5d5fef;
      }
    }
    @media (max-width: 900px) {
      justify-content: center;
      padding-left: unset;
    }
  }
}
.container-detail-header-home {
  .container-title-header-home {
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
      display: unset;
    }
  }
  .title-header-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */

    text-align: center;

    /* dark1/6 */

    color: #4a4396;

    cursor: pointer;

    @media (max-width: 900px) {
      text-align: left;
      color: #5d5fef;
    }
  }

  .menu-header-home {
    display: grid;
    align-items: center;

    .img-menu-header-home {
      display: flex;
      justify-content: center;
    }
  }
}

.badge {
  font-size: 12px;
  border: 1px solid #d9e8f0;
  padding: 3px 15px;
  border-radius: 40px;
}

.badge-admin {
  background: #e8f1f6;
  color: #0071a9;
}

.badge-location {
  color: #29a339;
  background: #eef6ef;
}

.badge-point {
  color: #5a7087;
  background: #f5f7f9;
}

.img {
  background: #eaedf1;
  color: #c2cfdb;
  font-weight: bold;
  border-radius: 5px;
  padding: 15px 5px;
}

.menu-notify {
  position: relative;
  top: 20px;
  right: 0px;
  margin: 0px;
  width: 450px;
  background: #212429;
  box-shadow: 4px 4px 7px #000000;
}
.menu-notify ul{
  background: #212429;
}

.menu-notify .header-notify {
  padding: 10px 0px 20px 10px;
}

.menu-notify .title {
 /* Head */

font-family: 'SF Pro Text';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;
display: flex;
align-items: center;

color: #FFFFFF;
padding-top:10px
}
.menu-notify .header-notify .seen-all {
  /* Small text */

font-family: 'SF Pro Text';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;
padding-top: 12px;
/* Grey/7 */

color: #777777;
text-align: center;

}
.menu-notify .option-notify{
  border-radius: 8px;
  margin: 0px 40px 0px 28px;
  padding: 0px 10px 0px 20px;
  height: 100%;
  &:hover {
    background: #333D47 ;
  }
}
.name-notify {
/* Subhead */

font-family: 'SF Pro Text';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* identical to box height */

text-align: center;
align-items: center;
word-break: break-word !important;
margin-bottom: 12px;
/* Neutral/5 */

color: #6158B8;
}
.content-notify{
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
display: flex;
align-items: center;

word-break: break-word !important;
/* Grey4 */

color: #CECFD0;
}
.time-notify{
    
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
display: flex;
align-items: center;

padding-top: 7px;

  /* Neutral/6 */
    
  color: #9187F2;
}
.menu-notify .option-notify-disable{
  border-radius: 8px;
  margin: 0px 40px 0px 28px;
  padding: 0px 10px 0px 20px;
  height: 100%;
  &:hover { 
    background: #333D47 !important;
  }
}
.menu-notify .option-notify-disable span{
  color: #777777;
}

.menu-account{
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.menu-notify .ant-menu-submenu-title:active, .ant-menu-item:active, .ant-menu:not(.ant-menu-horizontal, .menu-profile, .menu-career) .ant-menu-item-selected{
  background-color: #212429 ;
}

.menu-notify .footer-notify {
  // border-top: 2px solid #333D47 !important;
  text-align: center;
}
.menu-notify .footer-notify .see-more {
   /* Head */
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 21px;
display: flex;
align-items: center;
display: inline-block;
padding: 10px 0px 20px 0px;
margin-bottom: 10px;
/* Neutral/6 */

color: #544aac !important;
}.menu-notify li {
  word-break: break-all;
  white-space: pre-line;
}




.menu-notify-mobile {

  margin: 0px;
  background: #212429;
  
  box-shadow: 4px 4px 7px #000000 ;
}
.menu-notify-mobile ul{
  background: #212429;
}
.menu-notify-mobile .header-notify-mobile {
  //border-bottom: 2px solid #333D47;
  padding: 0px 0px 10px 0px;
}

.menu-notify-mobile .title-mobile {
  /* Head */
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 21px;
display: flex;
align-items: center;

/* Body */

color: #FFFFFF;
padding-top:10px;
}
.menu-notify-mobile .header-notify-mobile .seen-all-mobile {
  font-family: 'SF Pro Text';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 19px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
padding-top: 10px;
/* Grey/7 */

color: #777777;
}
.menu-notify-mobile .option-notify-mobile{
  border-radius: 8px;
  margin: 0px 20px 0px 18px;
  padding: 10px 10px 0px 20px;
  height: 100%;
  &:hover {
    background: #333D47 !important;
  }
}
.name-notify-mobile {
  /* Head */

font-family: 'SF Pro Text';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 21px;
display: flex;
align-items: center;
word-break: break-word !important;
margin-bottom: 9px;
/* Neutral/6 */

color: #544aac;
}
.content-notify-mobile{
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;

word-break: break-word !important;
/* Grey4 */

color: #CECFD0;
}
.time-notify-mobile{
    /* Head */

font-family: 'SF Pro Text';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 20px;
display: flex;
align-items: center;

padding-top: 7px;

color: #544aac;
}
.menu-notify-mobile .option-notify-disable-mobile{
  border-radius: 8px;
  margin: 0px 20px 0px 18px;
  padding: 10px 10px 0px 20px;
  height: 100%;
  &:hover { 
    background: #333D47 !important;
  }
}
.menu-notify-mobile .option-notify-disable-mobile span{
  color: #777777;
}

.menu-notify-mobile .ant-menu-submenu-title:active, .ant-menu-item:active, .ant-menu:not(.ant-menu-horizontal, .menu-profile, .menu-career) .ant-menu-item-selected{
  background-color: #212429;
}

.menu-notify-mobile .footer-notify-mobile {
 
  text-align: center;
}
.menu-notify-mobile .footer-notify-mobile .see-more-mobile {
   /* Head */
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 21px;
display: flex;
align-items: center;
display: inline-block;
padding: 10px 0px 20px 0px;
margin-bottom: 10px;
/* Neutral/6 */

color: #544aac !important;
}.menu-notify-mobile li {
  word-break: break-all;
  white-space: pre-line;
}

 .title-tour{
   color: #FFFFFF;
   font-size: 14px;
   font-weight: 500;
 }

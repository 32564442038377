.header-welcome {
  //height: 21px;
  margin-top: 20px;
  margin-left: 15px;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #d6d2ff;
}
.header-name {
  // height: 38px;
  margin-left: 15px;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  margin-top: 15px;
  margin-bottom: 18px;
  color: #ffffff;
  @media (max-height: 1200px) {
    font-size: 26px;
  }
}
.profile-info-account {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* White */

  color: #ffffff;
  padding-bottom: 20px;
  border-bottom: 2px solid #333d47;
  @media (max-width: 900px) {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #ffffff;
    border: none;
    padding-bottom: 15px;
  }
}
.profile-header-right {
  margin-top: 35px;
}
.profile-header-right .bottom img {
  width: 24px;
  height: 24px;
  color: #f0f0f0;
}
.profile-body {
  padding: 0px 100px 20px 40px;
  @media (max-width: 900px) {
    padding: 25px 25px 35px 25px !important;
  }
}
.profile-footer {
  position: sticky;
  width: 100%;
  bottom: 0;
  /* padding: 10px 17px 0 17px; */
  z-index: 1000;
  background: #212429;
  border-top: 2px solid #333d47;
}
.profile-body .profile-info-note {
  padding: 12px 0px 20px 0px;
  @media (max-width: 900px) {
    padding-bottom: 15px;
  }
}
.profile-body .profile-label {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Grey/7 */

  color: #777777;

  @media (max-width: 900px) {
    //font-family: 'SF Pro Text';
    //font-style: normal;
    //font-weight: 400;
    //font-size: 14px;
    //line-height: 17px;
    //display: flex;
    //align-items: center;
    //
    ///* Grey/7 */
    //
    //color: #777777;
  }
}
.profile-body .profile-label-service-use {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  padding: 16px 0px 12px 0px;
  /* Grey/7 */

  color: #777777;
  @media (max-width: 900px) {
    padding: 16px 0px 12px 0px;
  }
}
.profile-body .form-input-edit-profile {
  background: #484d51;
  border: none;
  height: 35px;
  color: #dbdbdb ;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    //background: #484D51;
    //border: none;
    //height: 35px;
    //color: #DBDBDB !important;
    //font-family: "SF Pro Text";
    //font-style: normal;
    //font-weight: 400;
    //font-size: 14px;
    //line-height: 17px;
    //display: flex;
    //align-items: center;
  }
  input {
    background: #484d51;
    color: #dbdbdb ;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
  }
}
.profile-body .profile-select-number-member .ant-select-selector {
  background: #484d51;
  border-radius: 0px !important;
  height: 35px !important;
  border: none;
  @media (max-width: 900px) {
    background: #484d51 ;
    border-radius: 0px !important;
    height: 35px !important;
  }
  .ant-select-selection-item {
    color: #dbdbdb ;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
  }
}
.select-dropdown-profile{
  background: #484D51;
}
.select-dropdown-profile
  .rc-virtual-list
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #2c3034 ;
}
.select-dropdown-profile
  .rc-virtual-list
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #2c3034 ;
  border-bottom: 0.5px solid #333d47;
}
.select-dropdown-profile .rc-virtual-list .ant-select-item-option {
  border-bottom: 0.5px solid #333d47;
  height: 40px;
}
.select-dropdown-profile .rc-virtual-list .ant-select-item-option-content {
  height: 30px;
  display: flex;
  align-items: center;
}

.profile-body .form-edit-profile label {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Grey/7 */

  color: #777777;
}
.profile-body .form-edit-profile .ant-form-item-control {
  min-width: 100%;
}
.profile-body .profile-sub-title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #dbdbdb;
  @media (max-width: 900px) {
    //font-family: 'SF Pro Text';
    //font-style: normal;
    //font-weight: 500;
    //font-size: 16px;
    //line-height: 19px;
    ///* identical to box height */
    //
    //display: flex;
    //align-items: center;
    //
    //color: #DBDBDB;
  }
}
.profile-body .avatar-user {
  padding: 18px 0px;
  @media (max-width: 900px) {
    padding: 15px 0px;
  }
}
.profile-body .button-load-img {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #dbdbdb;
  background: transparent;
  /* border: 1px solid; */
  padding: 8px 12px;
  border: 1px solid #3f4c58;
  cursor: pointer;
  @media (max-width: 900px) {
    //font-family: 'SF Pro Text';
    //font-style: normal;
    //font-weight: 500;
    //font-size: 16px;
    //line-height: 19px;
    //display: flex;
    //align-items: center;
    //color: #DBDBDB;
    background: transparent;
    /* border: 1px solid; */
    padding: 8px 12px;
    border: 1px solid #3f4c58;
  }
}
.profile-body .profile-button-cancel {
  height: 46px;
  margin: 35px 30px 0px 0px;

  border: 0.5px solid #cecfd0;
  border-radius: 8px;
  background: transparent;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  width: 100%;
  max-width: 150px;
  @media (max-width: 900px) {
    margin: 25px 10px 0px 0px;
    height: 44px;
    padding: 0px 20px;
    width: 100%;
  }
}
.profile-body .profile-button-submit {
  height: 46px;
  //padding: 0px 46px;
  margin-top: 35px;

  background: #6158b8;
  border-radius: 8px;

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #ffffff;
  width: 100%;
  max-width: 150px;
  @media (max-width: 900px) {
    margin: 25px 0px 0px 10px;
    height: 44px;
    padding: 0px 20px;
    width: 100%;
  }
}
.profile-body .package {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* SYmetris */

  color: #87bf3e;
}

.profile-body .profile-select-career .ant-select-selector {
  background: transparent !important;
  border: 1px solid #3f4c58 !important;
  border-radius: 0px !important;
  height: 35px !important;
  .ant-select-selection-search input {
    height: 35px;
  }
  .ant-select-selection-placeholder {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #dbdbdb;
  }
}
.profile-body .container-profile-info-account {
  border-bottom: 1px solid #333d47;
  padding-bottom: 20px;
  padding-top: 20px;
}
.profile-body .duration {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;

  /* Grey/7 */

  color: #777777;
}
.profile-body .package-infor button {
  background: #ffae11;
  border-radius: 8px;
  border: 0px #ffae11 solid;
  cursor: pointer;
}
.profile-body .package-infor button span {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;

  color: #ffffff;
}
// .profile-body .package-benefit {
//   border-bottom: 2px solid #212429;
// }
.profile-body .body-titles {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* Neutral/6 */

  color: #9187f2;
}
.profile-body .body-contents {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  /* Grey/7 */

  color: #777777;
}
.profile-body .body-numbers {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  display: flex;
  align-items: center;

  /* Neutral/8 */
  margin: 5px 0px 0px 25px;
  color: #f5f3ff;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.profile-header-right span {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Grey4 */

  color: #cecfd0;
}

.profile-midder {
  height: 2.32px;

  margin: 15px auto;

  background: #2c3034;
  border-radius: 8px;
}

.profile-user-modal .ant-modal-content {
  background: #24262b;
  border-radius: 30px;
}
.profile-user-modal .ant-modal-footer {
  display: none;
}
.profile-user-modal .ant-modal-body {
  padding: 40px 40px 30px 40px;
  @media (max-width: 1200px) {
    padding: 20px 20px 20px 20px;
  }
}
.profile-body .body-title {
  // margin: 35px 45px 0px 45px;
  // height: 120px;
  border-bottom: 1px solid #414e54;
  padding-bottom: 10px;
}
.profile-body .body-title .package {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  display: flex;
  align-items: center;
  padding-top: 8px;
  /* SYmetris */

  color: #87bf3e;
}
.profile-check-used {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  align-items: center;

  /* Neutral/6 */

  color: #9187f2;

  border: 2px solid #9187f2;
  border-radius: 14px;
  padding: 5px 0px;
  margin-top: 6px;
}
.profile-body .body-title .duration {
  /* Button */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;
  margin-top: 14px;
  padding-bottom: 20px;
  /* Grey/7 */

  color: #777777;
  // @media (max-height: 1200px) {
  //   font-size: 14px;
  // }
}

.profile-body .body-title button {
  // width: 170px;
  height: 48px;

  background: #ffa800;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}
.container-button-upgrade {
}
.profile-body .body-title button span {
  /* Button */
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  align-items: center;

  /* White */

  color: #ffffff;
}

.profile-body .package-benefit {
  // margin: 35px 45px 0px 45px;
  // height: 170px;
  // border-bottom: 1px solid #333d47;
  // padding: 20px 35px 20px 35px;
  padding: 30px 0px;
}

.profile-body .package-benefit-mobile {
  padding: 20px 0 0 0;
}

.profile-body .package-benefit-mobile .body-numbers {
  font-size: 16px !important;
}

.profile-body .body-titles {
  /* Head */

  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* Neutral/6 */

  color: #9187f2;

  @media (max-width: 1200px) {
    font-size: 17px;
  }
}

.profile-body .package-benefit .body-contents {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  text-align: left;
  // align-items: center;
  padding-bottom: 12px;
  /* Grey/7 */

  color: #777777;
}

.profile-body .package-benefit .body-numbers {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: left;

  /* White */

  color: #ffffff;

  @media (max-width: 1200px) {
    font-size: 20px;
  }
}

.profile-body .reports {
  margin: 35px 45px 0px 45px;
  //height: 160px;
  border-bottom: 1px solid #333d47;
}
.profile-body .reports .body-contents {
  /* Body */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;

  /* Grey4 */

  color: #cecfd0;
}
.profile-body .reports .body-days {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* Neutral/8 */

  color: #f5f3ff;
}
.profile-body .package-data {
  margin: 35px 45px 20px 45px;
  height: 152.3px;
  // border-bottom: 1px solid #333d47;
}
.profile-body .package-data .package-name {
  /* Button */

  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Grey/5 */

  color: #cecfd0;

  @media (max-width: 1200px) {
    font-size: 14px;
    padding-left: 4px;
  }
}

.profile-body .package-data .detail a {
  color: #87bf3e;
  border-bottom: 1px solid #87bf3e;
  cursor: pointer;
}
.profile-body .responses {
  margin: 35px 45px 0px 45px;
  height: 363px;
}

.profile-body .responses .responses-title {
  height: 20px;
}

.profile-body .responses .response-contents TextArea {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  display: flex;
  align-items: center;

  font-size: 15px !important;
  color: #ffffff !important;

  height: 211px;
  width: 100%;
  background: #212429;
  border-radius: 8px;
  margin-top: 10px;
}

.profile-body .responses .response-contents TextArea::placeholder {
  padding: 20px 0px 0px 10px;
  font-size: 15px;
}
.profile-body .responses button {
  float: right;
  width: 100px;
  height: 45px;
  margin-top: 20px;
  /* Neutral/7 */

  border: 1px solid #d6d2ff;
  box-sizing: border-box;
  border-radius: 8px;
  background: #2c3034;
  cursor: pointer;
}
.profile-body .responses button img {
  width: 20px;
  height: 20px;
  color: #d6d2ff;
}
.profile-body .responses button span {
  /* Body2 */

  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Neutral/7 */

  color: #d6d2ff;
}
.profile-body .title-table {
  /* Head */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  /* White */

  color: #ffffff;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
}

.profile-body .reports .ant-table-cell {
  height: 50px;
}
.profile-body .reports table span {
  padding-left: 20px;
}
.package-data-detail {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  // text-align: center;
  color: #cecfd0;
  margin-bottom: 6px;
  @media (max-width: 1200px) {
    font-size: 12px;
    padding-left: 4px;
  }
}
.body-number-total {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  padding: 2px 0px 0px 5px;
  /* White */

  color: #777777;
}
.alert-upgrade {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  /* Grey/5 */
  background-color: #2c3034;
  border: 0px;
  color: #cecfd0 !important;
}
.alert-upgrade .ant-alert-message {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;

  /* Grey/5 */

  color: #cecfd0;
}
.alert-upgrade .ant-alert-with-description .ant-alert-message {
}

.color-number {
  color: #87bf3e !important;
}
.profile-body .body-title .info-user .fullname-user {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #ffffff;
}
.profile-body .body-title .info-user .email-user {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.02em;

  padding: 10px 0px;
  /* White */

  color: #ffffff;

  opacity: 0.5;
}

.profile-body .body-title .info-user .phone-user {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.02em;
  padding-bottom: 15px;
  /* White */

  color: #ffffff;

  opacity: 0.5;
}
.profile-body .body-title .info-user .avatar-user {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 20px 0px 15px 0px;
  .edit_avatar {
    position: absolute;
    top: 77px;
    right: 222px;
    cursor: pointer;
    @media (max-width: 700px) {
      right: 110px;
    }
  }
}
.popup-edit-career .ant-modal-content {
  background: #2c3034;
  border-radius: 16px;
  .ant-modal-header {
    background: #2c3034;
    border-bottom: none;
    padding-bottom: 0px;
    border-radius: 16px;
    .ant-modal-title {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      display: flex;
      align-items: center;

      /* White */

      color: #ffffff;
    }
  }
}
.popup-edit-career .ant-modal-body {
  .label-profile-select-career {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding-bottom: 20px;
  }
  .profile-select-career {
    width: 100%;
    padding-bottom: 35px;
    .ant-select-selector {
      border-radius: 8px;
      border: none;
      background: #484d51;
      height: 45px;
      .ant-select-selection-item {
        color: #dbdbdb ;
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        height: 45px;
      }
    }
    .ant-select-arrow {
      top: 25px;
    }
  }
}
.profile-button-submit-modal {
  background: #6158b8;
  border-radius: 8px;
  border: none;
  height: 48px;
  span {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #ffffff;
    padding: 0px 5px;
  }
}
.popup-edit-info .ant-modal-content {
  background: #2c3034;
  border-radius: 16px;
  .ant-modal-body {
    .ant-form-item-label label {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      display: flex;
      align-items: center;

      /* White */

      color: #ffffff;
    }
    .popup-input-edit-password {
      background: #484d51;
      height: 42px;
      border-radius: 8px;
      border: none;
      input {
        background: #484d51;
        color: #dbdbdb ;
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
      }
    }
  }
}
.popup-edit-info
  .ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #484d51;
}

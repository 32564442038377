@import "styles/index";

#components-layout-demo-side {
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
}

.ant-layout-sider {
  background: $white;
}

.sidebar-open {
  flex: 0 0 270px !important;
  min-width: 270px !important;
  max-width: 270px !important;
  width: 270px !important;
  box-shadow: 0px 3px 3px -2px #00000033, 0px 3px 4px 0px #00000024,
    0px 1px 8px 0px #0000001f;
}

.ant-menu-submenu-title .anticon {
  color: #0071a9;
}

.sidebar-close {
  flex: 0 0 70px !important;
  min-width: 70px !important;
  max-width: 70px !important;
  width: 70px !important;
  margin-top: 24px !important;
  background: #2c3034;
  border-radius: 0 10px 10px 0;
  height: 750px;
  @media (max-width: 900px) {
    flex: 0 0 81px !important;
    min-width: 81px !important;
    max-width: 81px !important;
    width: 81px !important;
  }
}

.ant-menu-vertical {
  border-right: none;
}

.ant-menu-inline-collapsed {
  //width: 50px !important;
}

.ant-menu-vertical {
  .ant-menu-submenu-title {
    padding: 0 16px !important;
  }
}

.sidebar-right-custom {
  //height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  background: #2c3034;
}
.main-layout .sidebar-right-custom .ant-menu-item-selected {
  background-color: #4c449b !important;
}

.ant-menu-inline {
  .ant-menu-item {
    min-height: 40px !important;
    height: auto !important;
    display: flex;
    align-items: center;
    line-height: 22px !important;
  }
}

.ant-menu-submenu-title {
  font-weight: bold;
}

.sidebar-mms {
  .card {
    border-radius: 5px;
    width: 230px;
    background: #f5f7f9;
    padding: 10px;

    h3 {
      margin: 0 !important;
      font-size: 12px;
      font-weight: bold !important;
    }

    .img {
      background: #eaedf1;
      color: #c2cfdb;
      font-weight: bold;
      border-radius: 5px;
      padding: 15px 8px;
    }
  }
}

.main-menu {
  margin: 10px 10px 10px 10px;
  opacity: 0.7;
}

.main-menu:hover {
  opacity: 1;
}

.ant-layout-sider-children .sidebar-right-custom .ant-menu-item-selected {
  opacity: 1 !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  padding: 5px !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 8px;
  @media (max-width: 900px) {
    padding: 8px !important;
    width: 52px !important;
    height: 60px !important;
    margin-bottom: 25px !important;
  }
}

.menu .ant-drawer-body {
  padding: 10px 0 0 0;
}

.menu .ant-drawer-body .ant-menu-title-content a {
  font-family: SF Pro Text, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  opacity: 0.8;
}

.menu .ant-drawer-body .ant-menu-item-icon {
  padding-top: 15px;
  width: 50px;
  height: 72px;
}

.menu .ant-drawer-body .mobile .main-menu .ant-menu-item-icon {
  width: 27px;
  height: 57px;
}

.menu .ant-drawer-body .mobile .ant-menu-item {
  color: #ffffff;
  opacity: 1;
}

.menu .ant-drawer-body .mobile .ant-menu-item-selected {
  background-color: #4c449b;
  opacity: 1;
  .ant-menu-title-content a {
    opacity: 1;
  }
}
.menu .ant-drawer-body .mobile .ant-menu-item-selected::after {
  border-right: 0px !important;
}
.menu .mobile {
  background: #212429 ;
  border: none;
}

.drawer-custom {
  z-index: 10000;
}

.sidebar-mms
  .ant-layout-sider-children
  .ant-menu
  .ant-menu-item
  .ant-menu-item-icon {
  width: 20px;
  margin: 0 10px 10px 10px;
  vertical-align: middle;
}
.drawer-custom-home .ant-drawer-content-wrapper {
  // height: fit-content;
  // height: -webkit-fill-available;
  // display: inline-table;
}
//.drawer-custom-home .ant-drawer-content{
//  height: fit-content;
//}
.drawer-custom-home .ant-drawer-header {
  background: #dbdbdb;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-bottom: none;
  padding: 24px;
}
.drawer-custom-home .ant-drawer-body {
  padding: 0px;
}
.container-sidebar-home {
  display: flex;
  align-items: center;
  .title-sidebar-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* or 122% */

    color: #5d5fef;
  }
}
.sidebar-right-custom-home .container-menu-sidebar {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 0.5px solid rgba(33, 59, 150, 0.4);
  .content-menu-sidebar {
    padding: 15px 0px 15px 35px;
    .sub-title-sidebar-home {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      /* identical to box height, or 157% */

      color: #7a5a07;
    }
  }
}
.sidebar-right-custom-home {
  .menu-home-page {
    background: #FFFFFF;
    padding: 0px 0px !important;
    margin: 0px !important;
  }
  .ant-menu-item-selected {
    background-color: #FFFFFF !important;
  }
}
.sidebar-close .sidebar-right-custom .ant-menu-item-selected .ant-menu-item-icon img{
  filter: brightness(0) invert(1) !important;
}

// @use "../../layout/index2" as light;
// @use "../../layout/index3" as dark;

.main-container {
  height: 880px;
  padding: 0px;
  background-image: url("../../../assets/icon/background-register.png");
  background-size: 100% 100%;
  // background-color: transparent;
}
.main-container-mobile {
  padding: 0px;
  background-image: url("../../../assets/icon/background-register.png");
  background-size: 100% 100%;
  // background-color: transparent;
}
.form-register {
  background: rgba(0, 0, 0, 0.819);
}
.form-register-mobile {
  background: rgba(0, 0, 0, 0.819);
}
.title-register {
  /* Title/2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;

  margin-top: 70px;
  margin-bottom: 40px;
}

.form-register-mobile .title-register {
  /* Head */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #ffffff;

  margin-top: 50px;
  margin-bottom: 30px;
  margin: 50px 0px 30px 11%;
}
.input-register {
  background: #181a1f !important;
  /* Neutral/5 */

  border: 1px solid transparent !important;
  border-radius: 8px;
}

.form-register-mobile .input-register {
  justify-content: center;
  width: 82%;
  background: #181a1f;
  /* Neutral/5 */

  border: 1px solid transparent !important;
  border-radius: 8px;
}

.form-register input {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 18px;
  background: #181a1f;
  /* Neutral/5 */

  border: 1px solid transparent !important;
  border-radius: 8px;
  color: #ffffff !important;
}
::placeholder {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  letter-spacing: 0.02em;

  /* Grey/7 */

  color: #777777 !important;
}
.form-register-mobile input {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px;
  width: 100%;
  background: #181a1f;
  /* Neutral/5 */

  border: 1px solid transparent !important;
  border-radius: 8px;
  color: #ffffff !important;
}

.form-register button {
  height: 42px;
  border-radius: 8px;
  width: 100%;
}
.form-register-mobile button {
  justify-content: center;
  height: 42px;
  border-radius: 8px;
  width: 82%;
}
.text-button {
  /* Button2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  align-items: center;

  color: #ffffff;
  margin-left: 10px;
}
.social-button {
  margin-top: 20px;
  background: transparent;
}
.form-register-mobile .social-button {
  width: 100%;
}
.question-login {
  /* Button2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  // display: flex;
  align-items: center;

  /* Grey/7 */

  color: #777777;
  margin-top: 30px;
}
.login {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  // display: flex;
  align-items: center;

  /* Grey/7 */

  color: #ffffff;
}

.form-register-mobile .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}
.form-register-mobile .button-moible {
  display: flex;
  justify-content: center;
}
.form-register-mobile .ant-form-item-explain-error {
  // width: 82%;
  margin-left: 11%;
}
.term-color {
  color: white;
  font-size: 16px;
}
.main-header-landing-page {
  background: #ffffff;
  //padding: 0px 100px 0px 100px;
  display: flex;
  justify-content: center;
}
.main-layout-landing-page {
  background: #ffffff;
}
.site-layout-landing-page {
  background: #ffffff;
}
.main-content-landing-page {
  display: flex;
  justify-content: center;
}
.main-container-landing-page {
  background: #ffffff;
  max-width: 1200px;
}
.main-container-landing-page .title-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;

  display: flex;
  justify-content: center;
  text-align: center;
  color: #4c4397;
  padding-bottom: 45px;
  //padding-top: 30px;
  @media (max-width: 1200px) {
    font-size: 35px;
    line-height: 38px;
    text-align: center;
    //padding-top: 0px;
  }
  @media (max-width: 700px) {
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    padding-top: 20px;
  }
}
.main-container-landing-page .title-content4-landing-page-mobile {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;

  display: flex;
  justify-content: center;
  text-align: center;
  color: #4c4397;
}
.main-container-landing-page .content-text-landing-page-mobile {
  padding: 40px 5px 0px 10px;
}
.main-container-landing-page .text-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height */
  color: #58595b;
  @media (max-width: 700px) {
    font-size: 17px;
  }
}
.main-container-landing-page .content-landing-page {
  padding-bottom: 20px;
  @media (max-width: 1200px) {
    padding-left: 15px;
    padding-bottom: 15px;
  }
}
.main-container-landing-page .content-text-italics {
  padding: 40px 0px 8px 0px;
  @media (max-width: 1200px) {
    padding: 15px 0px 0px 0px;
  }
}
.main-container-landing-page .text-italics {
  font-family: "SF Pro Text";
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  justify-content: center;
  color: #58595b;
  @media (max-width: 700px) {
    font-size: 18px;
  }
}
.main-container-landing-page .container-button-looking-now {
  padding-bottom: 30px;
}
.main-container-landing-page .button-looking-now {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  /* identical to box height */
  color: #ffffff;
  text-shadow: 7px 10px 10px #262323;
  background: linear-gradient(
    118.28deg,
    #26a9e0 18.89%,
    #2c9ad5 25.52%,
    #3a74ba 43.77%,
    #4459a7 61.18%,
    #4a499b 76.11%,
    #4c4397 87.72%
  );
  border: none;
  width: 100%;
  border-radius: 0px 0px 38px 0px;
  box-shadow: 9px 11px 12px 4px #bdbaba;
  padding: 8px 0px;
  cursor: pointer;
  @media (max-width: 1200px) {
    font-size: 36px;
  }
  @media (max-width: 700px) {
    font-size: 32px;
  }
}
.main-container-landing-page .container-left1-landing-page {
  padding-top: 80px;
  @media (max-width: 1200px) {
    padding-top: 50px;
  }
  @media (max-width: 700px) {
    padding-top: 20px;
  }
}
.main-container-landing-page .container-right1-landing-page {
  display: flex;
  justify-content: center;
}
.content-main-header-landing-page {
  max-width: 1200px;
}
.main-container-landing-page .content-footer1-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  /* identical to box height */
  display: flex;
  justify-content: center;
  padding-top: 38px;

  color: #58595b;
  @media (max-width: 1200px) {
    display: none;
  }
}
.main-container-landing-page .img_footer1-landing-page {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.main-container-landing-page .content2-landing-page {
  padding: 18px 10px 30px 10px;
  background: #f3faff;
}

.main-container-landing-page .content2-landing-page .sub1-title-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 50px;
  /* identical to box height */
  display: flex;
  justify-content: center;
  padding: 10px 0px 20px 0px;
  color: #58595b;
  @media (max-width: 1200px) {
    font-size: 34px;
  }
  @media (max-width: 700px) {
    font-size: 28px;
  }
}
.main-container-landing-page .img-left-content2-landing-page {
  display: flex;
  justify-content: center;
  padding-right: 20px;
  @media (max-width: 1200px) {
    padding-bottom: 25px;
    padding-right: 0px;
  }
}
.main-container-landing-page .content2-landing-page .sub2-title-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  //justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 5px 0px 15px 20px;
  @media (max-width: 1200px) {
    font-size: 21px;
    text-align: center;
    padding-left: 0px;
    justify-content: center;
  }
  @media (max-width: 700px) {
    font-size: 21px;
    text-align: center;
    padding-left: 0px;
    justify-content: center;
  }
}
.main-container-landing-page
  .content2-landing-page
  .text-content2-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  color: #ffffff;
  @media (max-width: 700px) {
    font-size: 17px;
  }
}
.main-container-landing-page
  .content2-landing-page
  .img-right-content2-landing-page {
  display: flex;
  justify-content: center;
}
.main-container-landing-page
  .content2-landing-page
  .container-group-insight-landing-page {
  background: linear-gradient(
    118.28deg,
    #26a9e0 18.89%,
    #2c9ad5 25.52%,
    #3a74ba 43.77%,
    #4459a7 61.18%,
    #4a499b 76.11%,
    #4c4397 87.72%
  );
  .content-group-insight {
    padding: 20px 20px 30px 20px;
  }
  @media (max-width: 700px) {
    //padding: 20px;
    height: 315px;
  }
}
.main-container-landing-page
  .content2-landing-page
  .content2-right-landing-page {
  padding-top: 20px;
  padding-right: 15px;
  @media (max-width: 1200px) {
    padding: 25px 0px 20px 0px;
  }
}
.main-container-landing-page
  .content3-landing-page
  .text-content3-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height */
  text-align: justify;
  color: #6d6e70;
  @media (max-width: 1200px) {
    font-size: 17px;
  }
  @media (max-width: 700px) {
    font-size: 17px;
  }
}
.main-container-landing-page
  .content3-landing-page
  .detail-content3-landing-page {
  padding-bottom: 25px;
}
.main-container-landing-page .container-content4-landing-page {
  padding: 30px 25px 30px 25px;
  background: #f3faff;
}
.main-container-landing-page
  .container-content4-landing-page
  .box-content4-landing-page {
  height: 250px;
  border-radius: 0px 32px;
  background: #ffffff;
  box-shadow: 8px 11px 20px -5px #c3c3c3;
  display: block;
  @media (max-width: 1200px) {
    border-radius: 0px 32px 0px 0px;
  }
}
.main-container-landing-page
  .container-content4-landing-page
  .title1-box-content4-landing-page {
  background: #163a64;
  border-radius: 0px 32px 0px 0px;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.main-container-landing-page
  .container-content4-landing-page
  .title2-box-content4-landing-page {
  background: #b21f2f;
  border-radius: 0px 32px 0px 0px;
  display: grid;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.main-container-landing-page
  .container-content4-landing-page
  .text-box-content4-landing-page
  a {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: underline;
  color: #4c4397;
  padding: 30px;
}
.main-container-landing-page .container-content5-landing-page {
  padding: 0px 10px;
}
.main-container-landing-page
  .container-content5-landing-page
  .title-content5-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  color: #4c4397;
  @media (max-width: 1200px) {
    font-size: 28px;
  }
}
.main-container-landing-page
  .container-content5-landing-page
  .text-content5-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  //display: flex;
  justify-content: center;
  text-align: justify;
  color: #6d6e70;
  padding-top: 25px;
  padding-left: 20px;
  @media (max-width: 1200px) {
    font-size: 20px;
    padding: 18px 0px;
  }
  @media (max-width: 700px) {
    font-size: 17px;
    padding: 15px 0px;
  }
}
.main-container-landing-page .video-landing-page {
  @media (max-width: 1200px) {
    padding: 0px 80px;
  }
  @media (max-width: 700px) {
    padding: 0px 15px;
  }
}
.main-container-landing-page .video-landing-page iframe {
  border-radius: 12px;
}
.main-container-landing-page .container-content6-landing-page {
  border-radius: 24px;
  background: linear-gradient(
    90deg,
    #8bc53f 12%,
    #7fc241 17%,
    #60bb45 41%,
    #2daf4b 84%,
    #00a551 100%
  );
  box-shadow: 8px 12px 15px 4px #a3b4a0;
  @media (max-width: 1200px) {
    padding: 0px 10px;
  }
}
.main-container-landing-page
  .container-content6-landing-page
  .text-content6-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  //display: flex;
  justify-content: center;
  text-align: left;
  color: #ffffff;
  padding: 25px 38px 25px 60px;
  @media (max-width: 1200px) {
    padding: 5px 0px 0px 0px;
    font-size: 26px;
    line-height: 30px;
    //display: flex;
    align-items: center;
    text-align: center;
  }
  @media (max-width: 700px) {
    padding-bottom: 15px;
  }
}
.main-container-landing-page
  .container-content6-landing-page
  .button-start-content6-landing-page {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0f6838;

  cursor: pointer;
  border: none;
  width: 100%;
  height: 75px;
  border-radius: 40px;
  background: #ffffff;
  box-shadow: 2px -1px 35px -4px #5b5b5b;
  @media (max-width: 1200px) {
    font-size: 26px;
    height: 60px;
  }
}
.button-trial-free-gg {
  cursor: pointer;
}
.main-container-landing-page
  .container-content6-landing-page
  .content6-landing-page {
  padding: 30px;
  @media (max-width: 1200px) {
    padding: 25px;
  }
}
.main-container-landing-page
  .container-content6-landing-page
  .container-button-content6 {
  display: flex;
  align-items: center;
  justify-content: right;
  @media (max-width: 700px) {
    justify-content: unset;
  }
}
.main-container-landing-page
  .container-content4-landing-page
  .ant-carousel
  .carousel-landing-page-mobile
  .slick-dots-bottom {
  margin-top: 20px;
  position: initial !important;
}
.main-container-landing-page
  .container-content4-landing-page
  .ant-carousel
  .carousel-landing-page-mobile
  .slick-dots-bottom
  .slick-active
  button {
  background: #747272;
}
.main-container-landing-page
  .container-content4-landing-page
  .ant-carousel
  .carousel-landing-page-mobile
  .slick-dots-bottom
  li
  button {
  background: #786b6b;
}
.main-container-landing-page
  .container-content4-landing-page
  .ant-carousel
  .carousel-landing-page-mobile
  .slick-list {
  box-shadow: 19px 11px 20px -5px #c3c3c3;
  border-radius: 0px 32px;
}
.main-container-landing-page .banner-img-landing-page {
  background-size: auto;
  background: round;
  .content-banner-landing-page {
    padding: 15px 15px 15px 30px;
    .text-banner-ladding-page {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      display: flex;
      justify-content: center;
      text-align: center;
      color: #4c4397;
      padding: 65px 35px 30px 10px;
      @media (max-width: 1200px) {
        font-size: 32px;
      }
      @media (max-width: 700px) {
        font-size: 26px;
      }
    }
    .container-button-trial-free {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      .button-trial-free {
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 800;
        font-size: 27px;
        line-height: 38px;
        /* display: flex; */
        /* justify-content: center; */
        text-align: center;
        color: #ffffff;
        border-radius: 12px;
        border: none;
        background: #4c4397;
        padding: 12px 20px;
        box-shadow: -50px 98px 15px 8px rgb(0 0 0 / 49%);
        cursor: pointer;
        @media (max-width: 1200px) {
          padding: 20px;
        }
        @media (max-width: 700px) {
          padding: 12px 20px;
          font-size: 26px;
        }
      }
      @media (max-width: 1200px) {
        padding-top: 5px;
      }
    }
  }
  @media (max-width: 1200px) {
    background: unset;
    background-size: cover;
  }
}
.main-container-landing-page .container-icon-weather-element-landing-page {
  padding-top: 20px;
  .icon-weather-element-landing-page {
    border-radius: 14px;
  }
  .content-icon-weather {
    @media (max-width: 1200px) {
      padding: 0px !important;
    }
  }
}
.main-container-landing-page .text-important {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 42px;
  justify-content: center;
  text-align: left;
  color: #ffffff;
  @media (max-width: 1200px) {
    font-size: 30px;
  }
}
.form-register-from-landing-page {
  background: #ffffff;
  border: 1px solid #d6d2ff;
  border-radius: 10px;
  padding: 0px;
  .ant-modal-content {
    //border: 1px solid #D6D2FF;
    border-radius: 10px;
  }
  .ant-modal-body {
    padding: 25px 40px;
  }
}
.form-register-from-landing-page .mst-button-create-account {
  height: 48px;
  width: 100%;
  border-radius: 8px;
}
.form-register-from-landing-page .form-register-mode-light {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  color: #3c3c3c;
}
.title-register-mode-light {
  /* Title/2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #000000;

  //margin-top: 70px;
  margin-bottom: 20px;
}
.mst-social-button-mode-light {
  //margin-top: 20px;
  background: transparent;
  height: 50px;
  border: 1px solid #8982ca;
  border-radius: 8px;
  width: 100%;
  :hover {
    .text-button-mode-light {
      color: #ffffff;
    }
  }
}

.text-button-mode-light {
  /* Button2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  justify-content: center;
  align-items: center;

  color: #3c3c3c;
  margin-left: 10px;
}
.text-button-create-mode-light {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;
  padding: 10px 0px;
}
.input-register-mode-light {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  letter-spacing: 0.02em;

  /* grey/4 */

  color: #414e54;

  background: transparent;
  border: 1px solid #8982ca !important;
  border-radius: 8px;
  #passwordOld {
    background: transparent;
  }
  #passwordNew {
    background: transparent;
  }
}

.question-login-mode-light {
  /* Button2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  // display: flex;
  align-items: center;

  color: #5f5f5f;
  margin-top: 10px;
}
.login-mode-light {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  //display: flex;
  align-items: center;

  color: #6158b8;
}
.checkbox-insight-right-light-mode .ant-checkbox .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  background: transparent;
}
.checkbox-insight-right-light-mode .ant-checkbox {
  top: 0;
}
.checkbox-insight-right-light-mode
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  left: 2px;
  top: 6px;
  width: 7px;
  height: 12px;
  border: solid #9187f2;
  border-width: 0 4px 3px 0;
}

.container-content-home {
  padding: 0px;
  display: flex;
  justify-content: center;
  .banner-home {
    display: flex;
    justify-content: center;
  }
}
#myVideo {
  min-width: 100%;
  position: relative;
}
.content-banner-home {
  max-width: 1200px;
  position: absolute;
  top: 25%;
  width: 100%;
  @media (max-width: 900px) {
    width: 65%;
    bottom: 58px;
    top: unset;
  }
}
.container-content-home .title-home {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 78px;
  /* or 130% */
  display: flex;
  text-align: center;
  justify-content: center;

  color: #ffffff;
  @media (max-width: 900px) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */

    text-align: center;

    color: #ffffff;
  }
}
.container-content-home .middle-home {
  padding-top: 90px;
  max-width: 1200px;
  @media (max-width: 900px) {
    max-width: unset;
    width: 100%;
    padding: 20px 10px;
  }
  .video-landing-page {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
  }
  iframe {
    border-radius: 14px;
    width: 600px;
    @media (max-width: 900px) {
      height: 250px;
    }
  }
}
//.container-content-home .container-text-home{
//  .content-text-home{
//    display: flex;
//    justify-content: center;
//    padding: 0px 40px;
//  }
//}
.sub-title-content-home {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  /* or 128% */
  padding: 10px 0px 40px 0px;
  //max-width: 480px;
  color: #6d539f;
  @media (max-width: 900px) {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    padding: 10px 0px 20px 0px;
  }
}
.text-content-home {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* or 131% */
  //max-width: 480px;
  color: #000000;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 21px;
  }
}
.container-button-header {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  margin-top: 20px;
  @media (max-width: 900px) {
    padding: 10px 0px;
  }
  .button-trial-free {
    // @extend .background-color;
    // background: $primary-color;
    border-radius: 8px;
    border: none;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    /* identical to box height, or 100% */

    text-align: center;

    color: #513c06;

    height: 60px;
    width: 100%;
    cursor: pointer;
    background: linear-gradient(270deg, #FFB50B 0%, #FFD571 100%);
    @media (max-width: 900px) {
      font-size: 16px;
      line-height: 22px;
      /* identical to box height, or 138% */

      text-align: center;

      color: #513c06;

      height: 42px;
    }
  }
  .button-contact {
    background: #fdf6fe;
    border-radius: 8px;
    border: none;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    /* identical to box height, or 100% */

    text-align: center;

    color: #000000;
    height: 60px;
    width: 100%;
    cursor: pointer;
    @media (max-width: 900px) {
      background: #fdf6fe;
      /* Iris/80 */

      border: 1px solid #7879f1;
      border-radius: 8px;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height, or 138% */

      text-align: center;

      /* Iris/100 */

      color: #5d5fef;
      height: 42px;
    }
  }
}
.conditions-home {
  //border: 20px solid #FFFFFF;
  box-shadow: 0px 4px 100px rgb(78 78 78 / 20%);
  border-radius: 50px;
  //background: linear-gradient(85.79deg, #A36EFE 0%, #9555EB 100%);
  //padding: 45px 40px 45px 40px;
  @media (max-width: 900px) {
    border: 20px solid #ffffff;
    box-shadow: 0px 4px 100px rgb(78 78 78 / 20%);
    border-radius: 50px;
    background: linear-gradient(85.79deg, #a36efe 0%, #9555eb 100%);
    //padding: 45px 40px 45px 40px;
    margin-bottom: 22px;
    border-radius: 70px;
    height: 308px;
  }
}
.conditions-home-selected {
  background: linear-gradient(148.55deg, #fed978 0%, #ffb41d 100%);
  border-radius: 70px;
  margin-bottom: 20px;
  .detail-content-conditions-home {
    .content-select-conditions-home {
      height: 100%;
      @media (max-width: 900px) {
        padding: 55px 45px;
        height: 320px;
      }
    }
    .sub-title-conditions-home {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      /* identical to box height, or 129% */

      text-align: center;
      padding: 42px 20px 20px 20px;

      color: #7a5a07;
      @media (max-width: 900px) {
        font-size: 24px;
        line-height: 31px;
        padding: 0px 0px 25px 0px;
      }
    }
    .text-conditions-home {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      /* or 131% */

      text-align: center;
      padding: 0px 45px 50px 45px;
      color: #7a5a07;
      @media (max-width: 900px) {
        font-size: 16px;
        line-height: 21px;
        padding: 0px 15px 40px 15px;
      }
      .text-more-conditions-home {
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        /* identical to box height, or 131% */

        text-align: center;

        color: #7a5a07;
      }
    }
    .text-more-conditions-home {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height, or 131% */

      text-align: center;

      color: #7a5a07;
    }
  }
}

.conditions-home {
  .content-conditions-home {
    padding: 0px;
  }
  .detail-content-conditions-home {
    border-top: 20px solid #ffffff;
    border-bottom: 20px solid #ffffff;
    height: 320px;
    //border-right: 0px;
    //box-shadow: 0px 4px 100px rgb(78 78 78 / 20%);
    //border-radius: 50px 0px 50px 0px;
    background: linear-gradient(179.79deg, #a36efe 0%, #9555eb 100%);
    @media (max-width: 900px) {
      border: none;
      height: unset;
      background: unset;
    }
    .sub-title-conditions-home {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
      /* identical to box height, or 129% */

      text-align: center;
      padding: 42px 20px 20px 20px;

      color: #ffffff;
      @media (max-width: 900px) {
        font-size: 24px;
        line-height: 31px;
        padding: 48px 0px 15px 0px;
      }
    }
    .text-conditions-home {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      /* or 131% */

      text-align: center;
      padding: 0px 45px 50px 45px;
      color: #e5cff2;
      @media (max-width: 900px) {
        font-size: 16px;
        line-height: 21px;
        padding: 18px 30px 0px 30px;
      }
    }
    .text-more-conditions-home {
      font-family: "SF Pro Text";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      /* identical to box height, or 131% */

      text-align: center;

      color: #7a5a07;
    }
  }
}
.conditions-home
  .detail-content-conditions-home
  .content-select-conditions-home {
  height: 100%;
  @media (max-width: 900px) {
    padding: 5px 5px;
    //height: 225px;
  }
}
.detail-content-conditions-home-selected {
  position: relative;
  top: -20px;
  //height: 350px;
  background: linear-gradient(148.55deg, #fed978 0%, #ffb41d 100%);
  border-radius: 50px 50px 0px 0px;
  .sub-title-conditions-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    /* identical to box height, or 129% */
    padding: 48px 20px 20px 20px;
    text-align: center;

    color: #7a5a07;
  }
  .text-conditions-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* or 131% */
    padding: 0px 45px 50px 45px;
    text-align: center;

    color: #7a5a07;
  }
  .text-more-conditions-home {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    //padding-bottom: 50px;
    color: #7a5a07;
    cursor: pointer;
    svg {
      margin-left: 10px;
      width: 20px;
      height: 25px;
    }
  }
}
.hover-detail-content-conditions-home {
  position: relative;
  bottom: 0px;
  background: linear-gradient(148.55deg, #fed978 0%, #ffb41d 100%);
  border-radius: 50px 50px 0px 0px;
  height: 250px;
  .content-select-conditions-home {
    //height: 105px;
  }
  .sub-title-conditions-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    /* identical to box height, or 129% */

    text-align: center;
    padding-bottom: 16px;

    color: #7a5a07;
    background: transparent;
    //height: 100%;
  }
  .text-conditions-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* or 131% */

    text-align: center;
    //height: 100%;
    color: #7a5a07;
    //height: 60px;
    background: transparent;
  }
}
.middle-home .button-trial-now {
  background: linear-gradient(270deg, #fdb715 0%, #ffd571 100%);
  border-radius: 4px;

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  text-align: center;

  color: #7a5a07;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  @media (max-width: 900px) {
    width: 100%;
  }
}
.middle-home .sub-text-content-home {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* or 131% */

  color: #585858;
  padding-bottom: 30px;
}
.middle-home .container-content-home {
  display: flex;
  justify-content: flex-start;
}
.middle-home .sub-title-newspaper-content-home {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  /* identical to box height, or 128% */
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 30px;
  color: #6d539f;
}
.middle-home .container-newspaper .images-newspaper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  @media (max-width: 900px) {
    justify-content: center;
    padding-bottom: 35px;
  }

  .box-image-newspaper {
    display: flex;
    justify-content: center;
  }
}
.middle-home .container-newspaper {
  .container-detail-newspaper-home {
    padding-bottom: 20px;
    @media (max-width: 900px) {
      //padding: 10px;
      padding-bottom: 50px;
    }
    .box-newspaper-home {
      border-radius: 15px;
      margin-bottom: 20px;
      @media (max-width: 900px) {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      .detail-content-newspaper {
        img {
          border-radius: 18px;
          @media (max-width: 900px) {
            border-radius: 8px;
            height: 135px;
          }
        }
        @media (max-width: 900px) {
          height: 100%;
          padding: 10px;
        }
      }
    }
  }
  .title-newspaper-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    /* or 129% */
    //padding-top: 15px;

    color: #585858;
    @media (max-width: 900px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      padding-top: 0px;
      color: #4b439b;
    }
  }
  .time-newspaper-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */
    padding-top: 10px;
    color: #585858;
  }
  .name-newspaper {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */
    padding-bottom: 15px;
    color: rgba(0, 0, 0, 0.4);
    @media (max-width: 900px) {
      display: flex;
      align-items: flex-end;
      color: rgba(0, 0, 0, 0.4);
      padding-bottom: unset;
    }
  }
}
.middle-home .right-container-content-home {
  display: flex;
  justify-content: right;
}
.middle-home .container-button-trial-now {
  padding-top: 20px;
  @media (max-width: 900px) {
    //width: 100%;
    padding: 0px 15px;
  }
}
.carousel-package-price {
  border-radius: 14px 14px 0px 0px;
  .slick-list {
    margin: 0px 10px;
  }
}
.container-detail-carousel {
  border-radius: unset;
  border: none;
  background: #ffffff;
  // margin: 0px 10px;
  border-right: 1px solid #6d539f;
  height: 100%;
  @media (max-width: 900px) {
    border-radius: unset;
    border: none;
    border-radius: 14px 14px 0px 0px;
    border: 1px solid #6d539f;
  }
  .detail-package-home {
    //border: 1px solid #6D539F;
    border-top: none;
    padding: 22px 0px;
    @media (max-width: 900px) {
      padding-bottom: 30px;
    }
  }
  .container-info-package-home {
    padding: 18px 15px;
  }
  .image-package-home {
    display: flex;
    justify-content: center;
  }
}
.container-detail-carousel-selected {
  background: #ffb41d;
  border-radius: 14px 14px 0px 0px;
  height: 100%;
  @media (max-width: 900px) {
    height: unset;
  }
  .detail-package-home {
    @media (max-width: 900px) {
      padding-bottom: 30px;
    }
  }
  // margin: 0px 10px;
}
.container-detail-carousel-selected {
  .container-header-package-home {
    // border-bottom: 1px solid #6D539F;
    border-radius: 14px 14px 0px 0px;
    display: flex;
    justify-content: center;
    padding: 12px 0px;
    @media (max-width: 900px) {
      border-radius: 14px 14px 0px 0px;
      display: flex;
      justify-content: center;
      padding: 12px 0px 50px 0px;
    }
  }
  .name-package-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #7a5a07;
    padding-top: 25px;
    @media (max-width: 900px) {
      font-weight: 600;
      font-size: 24px;
      line-height: 31px;
    }
  }

  .price-package-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #7a5a07;
  }

  .info-package-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #7a5a07;
  }
  .container-info-package-home {
    padding: 18px 15px;
  }
  .image-package-home {
    display: flex;
    justify-content: center;
  }
}
.container-detail-carousel {
  .container-header-package-home {
    border-bottom: 1px solid #6d539f;
    border-radius: 14px 14px 0px 0px;
    display: flex;
    justify-content: center;
    padding: 12px 0px;
  }
  .name-package-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #6d539f;
  }

  .price-package-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #6d539f;
  }

  .info-package-home {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #6d539f;
  }
}
.carousel-package-price .slick-dots {
  bottom: -30px;
  .slick-active {
    background: #585858;
    border: 1px solid #000000;
    border-radius: 40px;
    width: 10px !important;
    height: 10px;
  }
  li {
    border: 1px solid #000000;
    border-radius: 40px;
    width: 10px;
    height: 10px;
    button {
      border-radius: 40px;
      width: 10px;
      height: 10px;
      background: transparent !important;
    }
  }
}
.middle-home .container-button-register-package-home {
  margin: 30px 25px 10px 25px;
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    display: flow-root;
  }
  .button-register-package-home {
    border: 1px solid #6d539f;
    border-radius: 4px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */

    text-align: center;

    color: #6d539f;
    padding: 18px 15px;
    width: 95%;
    cursor: pointer;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
}
.container-button-register-package-home-selected {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  .button-register-package-home {
    background: linear-gradient(270deg, #fdb715 0%, #ffd571 100%);
    border: none;
    border-radius: 4px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */

    text-align: center;

    color: #7a5a07;
    padding: 18px 15px;
    width: 95%;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
}
.slider-package-price-home {
  .slick-list {
    overflow: hidden;
    border-radius: 14px 14px 0px 0px;
    border: 1px solid #6d539f;
    background: #ffffff;
  }
  .slick-track {
    display: flex;
    .slick-slide > div {
      height: 100%;
    }
  }
}
.middle-home-background {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: 78px;
  background-size: 42% 45%;
}
.middle-home-condition-background {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: 132px;
  background-size: 43% 486px;
  height: 630px;
  .button-prev-home {
    position: absolute;
    right: 98px;
    top: 100px;
    opacity: 0.2;
  }
  .button-next-home {
    position: absolute;
    right: 0px;
    top: 100px;
  }
}

//@import "styles/index";
//@import "~antd/dist/antd.css";

#landingpage-id{
    overflow-y: scroll;
    background: #ffffff;
    overflow-x: hidden;
}
#landingpage-id::-webkit-scrollbar-thumb {
    background: #e8e7e7 !important;
    border-radius: 10px;

}
#landingpage-id::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    @media (max-width: 1200px) {
        width: 4px;
        height: 4px;
    }
}

/* Track */
#landingpage-id::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #FFFFFF;
    border-radius: 10px;
}

#landingpage-id::-webkit-scrollbar-thumb:hover {
    background: #454a58;
}

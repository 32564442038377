
#layouthome-id{
  //overflow-y: scroll;
  background: #ffffff;
  overflow-x: hidden;
  //height: 100;
}
#layouthome-id::-webkit-scrollbar-thumb {
  background: #e8e7e7 !important;
  border-radius: 10px;
}
#layouthome-id::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  @media (max-width: 1200px) {
    width: 4px;
    height: 4px;
  }
}

/* Track */
#layouthome-id::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFFFFF;
  border-radius: 10px;
}

#layouthome-id::-webkit-scrollbar-thumb:hover {
  background: #454a58;
}
.main-container-register {
  height: 880px;
  padding: 0px;
  // background-image: url("../../../assets/icon/background-register.png");
  background-size: 100% 100%;
  // background-color: transparent;
}
.main-container-register-mobile {
  padding: 0px;
  // background-image: url("../../../assets/icon/background-register.png");
  background-size: 100% 100%;
  // background-color: transparent;
}
.form-register-page {
  // background: rgba(0, 0, 0, 0.819);
}
.form-register-page-mobile {
  // background: rgba(0, 0, 0, 0.819);
}
.title-register {
  /* Title/2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;

  margin-top: 70px;
  margin-bottom: 40px;
}

.form-register-page-mobile .title-register {
  /* Head */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #ffffff;

  margin-top: 50px;
  margin-bottom: 30px;
  margin: 50px 0px 30px 11%;
}
.input-register-page {
  background: #181a1f;
  /* Neutral/5 */

  // border: 1px solid transparent !important;
  border-radius: 8px;
}

.form-register-page-mobile .input-register-page {
  justify-content: center;
  width: 82%;
  background: #181a1f;
  /* Neutral/5 */

  // border: 1px solid transparent !important;
  border-radius: 8px;
}

.form-register-page input {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 15px !important;
  line-height: 18px;
  // background: #181a1f;
  /* Neutral/5 */

  // border: 1px solid transparent !important;
  border-radius: 8px;
  // color: #ffffff !important;
}
::placeholder {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  letter-spacing: 0.02em;

  /* Grey/7 */

  color: #777777 !important;
}
.form-register-page-mobile input {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px;
  width: 100%;
  // background: #181a1f;
  /* Neutral/5 */

  // border: 1px solid transparent !important;
  border-radius: 8px;
  // color: #ffffff !important;
}

.form-register-page button {
  height: 42px;
  border-radius: 8px;
  width: 100%;
}
.form-register-page-mobile button {
  justify-content: center;
  height: 42px;
  border-radius: 8px;
  width: 82%;
}
.text-button {
  /* Button2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  align-items: center;

  color: #ffffff;
  margin-left: 10px;
}
.text-button-register{
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  align-items: center;

  // color: #ffffff;
  margin-left: 10px;
}
.social-button {
  margin-top: 20px;
  background: transparent;
}
.form-register-page-mobile .social-button {
  width: 100%;
}
.question-login {
  /* Button2 */

  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  // display: flex;
  align-items: center;

  /* Grey/7 */

  // color: #777777;
  margin-top: 30px;
}
.login {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  // display: flex;
  align-items: center;

  /* Grey/7 */

  // color: #ffffff;
}

.form-register-page-mobile .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}
.form-register-page-mobile .button-moible {
  display: flex;
  justify-content: center;
}
.form-register-page-mobile .ant-form-item-explain-error {
  // width: 82%;
  margin-left: 11%;
}
.term-color {
  color: black;
  font-size: 16px;
}
#register_passwordOld{
  background: transparent;
}
#register_passwordNew{
  background: transparent;
}
.social-button:hover, .social-button:active, .social-button:focus{
  background: #c9c9c9;
}